<template>
	<div class="home">
		<div class="bg-slant"></div>
		<div class="container">
			<div class="hero d-flex align-items-center justify-content-between position-relative">
				<div class="content">
					<h1 class="h1">
						Unite your email links.
					</h1>
					<div class="lead my-4">
						ShareLink is a zero configuration tool that allows you to easily place a share button for content in your marketing emails. Allow users to easily share articles, products and any other content you want from your emails.
					</div>
					<div class="cta" v-if="!isAuthenticated">
						<b-button to="/login" variant="primary">
							Get Started For Free
						</b-button>
					</div>
				</div>
				<div class="images position-relative">
					<img src="@/assets/images/screens/hero-lg.png" class="corners drop">
					<img src="@/assets/images/screens/hero-sm.png" class="small corners drop">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="link-generator-widget corners drop">
				<div class="layout d-flex justify-content-between">
					<div class="w-100">
						<div class="h3">
							Free Link Generator
						</div>
						<div>
							<div class="d-flex w-100">
								<div class="flex-grow-1 mr-4">
									<label>
										Title
									</label>
									<b-form-input v-model="title" style="height: 3rem;" placeholder="Title" class="w-100"></b-form-input>
								</div>
								<div class="flex-grow-1">
									<label>
										Site URL
									</label>
									<b-form-input v-model="url" style="height: 3rem;" placeholder="URL"></b-form-input>
								</div>
							</div>
							<div class="mt-4">
								<label>
									Description
								</label>
								<b-form-textarea v-model="description" placeholder="Description" class="w-100" rows="5" style="padding: 1rem !important;"></b-form-textarea>
							</div>
							<div class="mt-4">
								<label>Theme</label>
								<b-form-radio-group
									v-model="theme"
									:options="themeOptions"
									class="mb-3"
									value-field="item"
									text-field="name"
									disabled-field="notEnabled"
								></b-form-radio-group>
							</div>
						</div>
					</div>
					<div class="output-wrapper ml-5 pl-5 border-left">
						<div class="output corners">
							<div class="h4">
								Your Generated Link
							</div>
							<div class="code mt-3 mb-4">
								{{link}}
							</div>
							<div class="cta">
								<b-button class="m-2" @click.prevent="copyLink" variant="primary">
									<span class="material-icons-outlined">
										content_copy
									</span>
									Click to copy
								</b-button>
								<b-button class="m-2 text-white" target="_blank" :href="link" variant="secondary">
									<span class="material-icons-outlined">
										visibility
									</span>
									Open Preview
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container" v-if="!isAuthenticated">
			<div class="table-responsive">
				<table class="table">
					<thead>
						<tr>
							<th class="position-relative" style="width: 34%;">
								<div class="hack"></div>
							</th>
							<th class="fill corner-top-left" style="width: 22%;">
								<div class="h4">
									Basic
								</div>
								<div class="h2 text-primary">
									Free
								</div>
								<b-button to="/create-account" variant="primary">
									Sign up
								</b-button>
							</th>
							<th class="fill" style="width: 22%;">
								<div class="h4">
									Pro
								</div>
								<div class="h2 text-primary">
									$24.99<span>/mo/do</span>
								</div>
								<b-button href="mailto:hello@sharelink.email" variant="primary">
									Contact Us
								</b-button>
							</th>
							<th class="fill corner-top-right" style="width: 22%;">
								<div class="h4">
									Enterprise
								</div>
								<div class="h2 text-primary">
									Custom
								</div>
								<b-button href="mailto:hello@sharelink.email" variant="primary">
									Contact Us
								</b-button>
							</th>
						</tr>
					</thead>
					<tbody class="drop corners">
						<tr>
							<th scope="row" class="border-0 corner-top-left">
								Share Links
							</th>
							<td style="border: 0;">
								<span class="material-icons-outlined text-primary">check</span>
							</td>
							<td style="border: 0;">
								<span class="material-icons-outlined text-primary">check</span>
							</td>
							<td style="border: 0;">
								<span class="material-icons-outlined text-primary">check</span>
							</td>
						</tr>
						<tr>
							<th scope="row">
								Click/Open Tracking
							</th>
							<td align="center">
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								5MM events/domain/mo
							</td>
							<td>
								> 5MM events/domain/mo
							</td>
						</tr>
						<tr>
							<th scope="row" class="text-start corner-bottom-left">
								DMARC Bucket
							</th>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined text-primary">check</span>
							</td>
							<td>
								<span class="material-icons-outlined text-primary">check</span>
							</td>
						</tr>
						<tr>
							<th scope="row" class="text-start corner-bottom-left">
								Custom Reports
							</th>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined text-primary">check</span>
							</td>
						</tr>
						<tr>
							<th scope="row" class="text-start corner-bottom-left">
								Whitelabel Domains
							</th>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined text-primary">check</span>
							</td>
						</tr>
						<tr>
							<th scope="row" class="text-start corner-bottom-left">
								Email Support
							</th>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined text-primary">check</span>
							</td>
							<td>
								<span class="material-icons-outlined text-primary">check</span>
							</td>
						</tr>
						<tr>
							<th scope="row" class="text-start corner-bottom-left">
								Phone Support
							</th>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td>
								<span class="material-icons-outlined">remove</span>
							</td>
							<td class="corner-bottom-right">
								<span class="material-icons-outlined text-primary">check</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- <ShareCard :url="url" :title="title" :description="description" /> -->
		<!--
				<b-form>
					<b-form-input v-model="title" placeholder="Title" class="mb-1"></b-form-input>
					<b-form-textarea v-model="description" placeholder="Description" class="mb-1"></b-form-textarea>
					<b-form-input v-model="url" placeholder="URL"></b-form-input>
				</b-form>
				<div class="code">
					<code>{{link}}</code>
				</div>
				<ShareCard :url="url" :title="title" :description="description" />
			-->
		<footer class="footer">
			<div class="container">
				<div class="icon">
					<Icon />
				</div>
				<div class="layout d-flex align-items-end justify-content-between" v-if="!isAuthenticated">
					<div>
						<div class="h3">
							Ready to get started?
						</div>
						<div class="cta">
							<b-button to="/create-account" variant="primary">
								Sign Up
							</b-button>
						</div>
					</div>
					<div v-if="false">
						<div class="pages d-flex text-right">
							<div class="group">
								<div class="item">
									<a href="#">
										Features
									</a>
								</div>
								<div class="item">
									<a href="#">
										Pricing
									</a>
								</div>
								<div class="item">
									<a href="#">
										FAQs
									</a>
								</div>
							</div>
							<div class="group">
								<div class="item">
									<a href="#">
										Privacy Policy
									</a>
								</div>
								<div class="item">
									<a href="#">
										Terms of Use
									</a>
								</div>
								<div class="item">
									<a href="mailto:hello@sharelink.email">
										Contact Us
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="copyright d-flex justify-content-end small">
					&copy; 2022 sharelink.email &mdash; All rights reserved.
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import Icon from '@/assets/images/icon.svg';
	export default {
		name: 'Home',
		components: {
			Icon
		},
		data() {
			return {
				title: 'ShareLink',
				description: 'Share links from your emails or other sources easily, without configuration or technical knowledge :D',
				url: 'https://sharelink.email',
				theme: null,
				themeOptions: [
					{ item: null, name: 'Default' },
					{ item: 'dark', name: 'Dark' },
				]
			}
		},
		computed: {
			link() {
				return `${location.protocol}//${location.host}/share?url=${encodeURIComponent(this.url)}&title=${encodeURIComponent(this.title).replaceAll("'", '%27')}&description=${encodeURIComponent(this.description).replaceAll("'", '%27')}${this.theme ? `&theme=${this.theme}` : ''}`;
			}
		},
		methods: {
			copyLink () {
				this.$copyText(this.link);
				this.$bvToast.toast(`The link was added to your clipboard`, {
					title: 'Copied',
					autoHideDelay: 5000,
					toaster: 'b-toaster-bottom-right',
					variant: 'success'
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		max-width: var(--container-lg);
		margin: auto;
		padding: 0 1.5rem;
	}

	.hero {
		margin: 8rem 0;

		@media (max-width: 1050px) {
			flex-direction: column;
			margin: 4rem 0;
		}
	}

	.content {
		max-width: 450px;

		.h1 {
			font-size: 3.75rem;
			font-weight: var(--font-weight-black) !important;
			line-height: 1.1;
		}

		@media (max-width: 1050px) {
			margin-bottom: 3rem;
		}
	}

	.images {
		max-width: 520px;
		img {
			max-width: 100%;
			&.small {
				position: absolute;
				bottom: 10%;
				left: 0;
				transform: translateX(-2rem);
				max-width: 280px;
			}
		}
	}

	.link-generator-widget {
		background-color: var(--color-white);
		padding: 2rem;
		margin-bottom: 8rem;

		@media (max-width: 1050px) {
			.layout {
				flex-direction: column;
			}
			.output-wrapper {
				border: 0 !important;
				margin-left: 0 !important;
				margin-top: 2rem;
				padding-left: 0 !important;
			}
			.output {
				max-width: 100%;
			}
		}
		.h3 {
			margin-bottom: 2rem;
		}
	}

	.output {
		max-width: 300px;
		background-color: #FDEAE3;
		background-image: linear-gradient(#FDEAE3, #FEE2EF);
		padding: 2rem;

		.h4 {
			font-size: 1rem;
		}

		.code {
			word-wrap: break-word;
			font-size: 0.875rem;
		}
	}

	.footer {
		padding: 3rem 0;
		background-color: var(--color-white);
		.icon {
			img {
				max-width: 3rem;
			}
		}
		@media (max-width: 700px) {
			.icon {
				text-align: center;
			}
			.layout {
				flex-direction: column;
				align-items: center !important;
				justify-content: center !important;
				text-align: center;
			}

			.pages {
				margin-top: 4rem;
				text-align: center !important;
			}

			.copyright {
				justify-content: center !important;
			}
		}

		.h3 {
			margin-bottom: 2rem;
		}
		.container {
			width: 100%;
			max-width: var(--container-lg);
		}

		.h3 {
			margin-top: 3rem;
			margin-bottom: 1.5rem;
		}

		.pages {
			.group {
				div {
					&:not(:last-of-type) {
						margin-bottom: 0.35rem;
					}
				}
			}
			.group:first-of-type {
				margin-right: 4rem;
			}
			a {
				color: inherit;
				font-size: 0.938rem;
				font-weight: var(--font-weight-bold) !important;
			}
			@media (max-width: 550px) {
				display: block;
			}
		}

		.copyright {
			margin-top: 3rem;
			padding-top: 3rem;
			border-top: 1px solid #FDEAE3;
			color: var(--color-mid-gray);
		}
	}

	.bg-slant {
		width: 120vw;
		height: 350px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -3rem) rotate(-5deg);
		background-color: #FDEAE3;
		background-image: linear-gradient(to right, #FDEAE3, #FEE2EF);
	}

	.table-responsive {
		overflow: visible;
		margin-bottom: 8rem;
		.table {
			color: inherit;
		}
		thead {
			th {
				border: 0;
				text-align: center;
				padding: 2rem;
				&.fill {
					background-color: var(--color-white);
				}
				.hack {
					width: 120%;
					height: 120%;
					z-index: 998;
					position: absolute;
					bottom: 0;
					right: 0;
					background-color: var(--color-peach-bg);
				}

				.h2 {
					font-size: 1.75rem;
					background-color: #FC25B7;
					background-image: linear-gradient(#FC25B7, #FF7832);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					font-weight: var(--font-weight-black);
					span {
						font-size: 1rem;
					}
				}

				.h4 {
					font-size: 1rem;
					font-weight: var(--font-weight-black);
				}
			}
		}
		tbody {
			th {
				padding: 1.5rem;
				background-color: var(--color-white);
			}
			td {
				background-color: var(--color-white);
			}
		}
		td {
			padding: 1.5rem;
			font-size: 0.75rem;
			text-align: center;
			vertical-align: middle;
			&.text-start {
				text-align: left !important;
			}
		}

		.material-icons-outlined {
			color: var(--color-mid-gray);
			&.text-primary {
				background-color: #FC25B7;
				background-image: linear-gradient(#FC25B7, #FF7832);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}
</style>
